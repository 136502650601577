import { createContext, ReactNode, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { FlowContainerFragmentFragment } from '~/graphql/api/sdk';
import { useShopifySdk } from '~/hooks';
import { showToast } from '~/lib';

const messages = defineMessages({
  error: 'Error adding product to card.',
});

interface ProviderProps {
  children: ReactNode;
  checkoutId?: string;
  flowContainer: FlowContainerFragmentFragment;
  redirectUrl?: string;
  storefrontAccessToken?: string;
}

interface ContextProps {
  addToCart(variantId?: string): Promise<boolean>;
  addSessionAttribute(sessionId: string): void;
  checkoutId?: string;
  isHeadless: boolean;
  redirectUrl: string;
  shopifySdk: ReturnType<typeof useShopifySdk>;
}

export const HeadlessContext = createContext<ContextProps>({} as ContextProps);

export const HeadlessProvider = ({
  children,
  checkoutId: checkoutIdProp,
  flowContainer,
  redirectUrl,
  storefrontAccessToken,
}: ProviderProps) => {
  const intl = useIntl();
  const shopifySdk = useShopifySdk(storefrontAccessToken || '');

  const [checkoutId, setCheckoutId] = useState(checkoutIdProp);

  const addSessionAttribute = async (sessionId: string) => {
    try {
      let currentCheckoutId = checkoutId || '';

      if (!checkoutId || checkoutId === 'undefined') {
        const result = await shopifySdk.checkoutCreate({ input: {} });

        if (!result.checkoutCreate?.checkout) {
          return;
        }

        currentCheckoutId = result.checkoutCreate?.checkout.id;
        setCheckoutId(currentCheckoutId);
      }

      shopifySdk.checkoutAttributesUpdateV2({
        checkoutId: currentCheckoutId,
        input: {
          customAttributes: [
            {
              key: `ppf-session: ${flowContainer.slug}`,
              value: sessionId,
            },
          ],
        },
      });
    } catch (e) {}
  };

  const addToCart = async (variantId?: string) => {
    try {
      if (!checkoutId || !variantId) {
        throw new Error();
      }

      await shopifySdk.checkoutLineItemsAdd({
        checkoutId,
        lineItems: [
          {
            quantity: 1,
            variantId,
          },
        ],
      });

      return true;
    } catch (e: any) {
      showToast(e?.message || intl.formatMessage(messages.error), 'error');
      return false;
    }
  };

  return (
    <HeadlessContext.Provider
      value={{
        addSessionAttribute,
        addToCart,
        checkoutId,
        isHeadless: !!storefrontAccessToken,
        redirectUrl: redirectUrl || '/cart',
        shopifySdk,
      }}
    >
      {children}
    </HeadlessContext.Provider>
  );
};
