import { ReactNode, useMemo } from 'react';
import { Flex } from '@storyofams/react-ui';
import { m } from 'framer-motion';
import { defineMessages, useIntl } from 'react-intl';

import { useLocation } from 'react-router';
import config from '~/config';
import { convertCSStoObject } from '~/context/helper';
import { File, FlowColorScheme, FlowNodeLayout } from '~/graphql/api/sdk';
import { useFlow, useProgress } from '~/hooks';
import { Container } from './Container';
import { Media } from './Media';
import { TopNav } from './TopNav';

const MotionFlex = m(Flex);

const messages = defineMessages({
  close: 'Close',
});

interface LayoutProps {
  children: ReactNode;
  current?: number;
  hidden?: boolean;
  exitCloseText?: boolean;
}

export const Layout = ({
  children,
  current,
  exitCloseText,
  hidden,
  ...props
}: LayoutProps) => {
  const intl = useIntl();
  const { flow, hasConditionalLogic, totalQuestions } = useFlow();
  const { pathname } = useLocation();
  const { currentQuestionNumber } = useProgress();

  const bg = useMemo(() => {
    switch (flow.colorScheme) {
      case FlowColorScheme.Cool:
        return '#F4F6FB';
      case FlowColorScheme.Warm:
        return '#FBF8F4';
      default:
        return '#F5F5F5';
    }
  }, [flow.colorScheme]);

  const flowNode = useMemo(
    () =>
      pathname !== '/results'
        ? flow.nodes?.[typeof current !== 'undefined' ? current : 0 || 0]
        : undefined,
    [flow, current],
  );

  return (
    <MotionFlex
      style={convertCSStoObject(flow?.cssEditor)['.background']}
      bg={bg}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={config.transition}
      flexDirection={
        flowNode?.layout === FlowNodeLayout.MediaCoverRight
          ? 'row-reverse'
          : 'row'
      }
      flex="1"
      {...props}
    >
      {!!flowNode &&
        [
          FlowNodeLayout.MediaCoverLeft,
          FlowNodeLayout.MediaCoverRight,
        ].includes(flowNode.layout) &&
        (flowNode.image || flowNode.video) && (
          <MotionFlex
            display={['none !important', 'flex !important']}
            flex="1"
            height="auto"
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={config.transition}
          >
            <Media
              image={flowNode.image}
              video={flowNode.video}
              alt=""
              minHeight="100%"
              sizes="570px"
              fullWidth
              cover
            />
          </MotionFlex>
        )}

      <Container
        display="flex"
        flexDirection="column"
        flex="1"
        pb="80px"
        px={
          !!flowNode &&
          [
            FlowNodeLayout.MediaCoverLeft,
            FlowNodeLayout.MediaCoverRight,
          ].includes(flowNode.layout)
            ? [2, 4]
            : 2
        }
      >
        <TopNav
          current={current ? currentQuestionNumber : undefined}
          total={current && !hasConditionalLogic ? totalQuestions : undefined}
          closeText={
            exitCloseText ? intl.formatMessage(messages.close) : undefined
          }
          hasStepIndicator={flow.hasStepIndicator}
          logo={flow.logo as File}
          hidden={hidden}
        />

        {children}
      </Container>
    </MotionFlex>
  );
};
