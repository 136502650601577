import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  errorTitle: 'An error has occurred',
  loadError: 'The finder could not be loaded. Please try again later.',
  backToWebsite: 'Back to main website',
  unexpectedError:
    'Our team has been notified and is working to resolve the issue. Please try again soon.',
  refresh: 'Refresh the page',
});
