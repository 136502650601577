import { createContext, useMemo, ReactNode } from 'react';
import validator from 'validator';
import { ActiveFlowContainerQuery, FlowNodeType } from '~/graphql/api/sdk';

type Flow = NonNullable<
  ActiveFlowContainerQuery['activeFlowContainer']
>['flow'];

interface ProviderProps {
  children: ReactNode;
  flow: Flow;
}

interface ContextProps {
  flow: Flow;
  hasConditionalLogic: boolean;
  hasWelcomeScreen: boolean;
  hasEmailStep: boolean;
  totalQuestions: number;
}

export const FlowContext = createContext<ContextProps>({} as ContextProps);

export const FlowProvider = ({ children, flow }: ProviderProps) => {
  const hasWelcomeScreen = useMemo(
    () => flow?.nodes?.[0]?.type === FlowNodeType.Welcome,
    [flow],
  );
  const hasEmailStep = useMemo(
    () => flow?.nodes?.[flow?.nodes?.length - 1]?.type === FlowNodeType.Email,
    [flow],
  );
  const hasConditionalLogic = useMemo(() => {
    const distances = flow.nodes?.[hasWelcomeScreen ? 1 : 0]?.distanceToEnd;

    return !!distances && distances.max !== distances.min;
  }, [flow, hasWelcomeScreen]);

  const totalQuestions = useMemo(
    () => flow?.nodes?.length + -(hasWelcomeScreen ? 1 : 0),
    [flow, hasWelcomeScreen],
  );

  //Due to issues with some colors being stored without a prefix of "#" we need to update it
  //This can be remove in the future
  if (flow.primaryColor?.[0] !== '#') {
    const newPrimaryColor = '#' + flow.primaryColor;

    //Confirm that newPrimaryColor is still a hexColor with an added prefix
    if (validator.isHexColor(newPrimaryColor)) {
      flow.primaryColor = newPrimaryColor;
    }
  }

  return (
    <FlowContext.Provider
      value={{
        flow,
        hasConditionalLogic,
        hasEmailStep,
        hasWelcomeScreen,
        totalQuestions,
      }}
    >
      {children}
    </FlowContext.Provider>
  );
};
