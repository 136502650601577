import { Flex } from '@storyofams/react-ui';
import lazysizes from 'lazysizes';
import 'lazysizes/plugins/blur-up/ls.blur-up';
import ReactDOM from 'react-dom';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Switch, Route, HashRouter } from 'react-router-dom';

import { Providers } from '~/components';
import { Container as ToastContainer } from '~/components/Toast/Container';
import { App } from '~/containers';
import { loadMessages } from '~/lib';
import { CSSReset } from '~/styles/CSSReset';

import 'react-toastify/dist/ReactToastify.min.css';
import { FlowLocale } from './graphql/api/sdk';
import { polyfill } from './lib/polyfills';

lazysizes.cfg.blurupMode = 'auto';
if (process.env.REACT_APP_SENTRY_DSN) {
  import('~/lib/sentry').then((m) => m.initSentry());
}

const flow = (window as any)?.__INITIAL_STATE__?.flowContainer?.flow;
const locale = flow?.locale;
const translationData = flow?.translation;
const isRtl = locale === FlowLocale.Custom && translationData?.isRtl;

const render = (messages) => {
  ReactDOM.render(
    <Providers
      isRtl={isRtl}
      locale={
        (locale === FlowLocale.Custom
          ? translationData?.locale
          : locale?.toLowerCase()) || 'en'
      }
      messages={messages}
    >
      <Flex flexDirection="column" flex="1" dir={isRtl ? 'rtl' : undefined}>
        <HashRouter>
          <Switch>
            <Route component={App} />
          </Switch>
        </HashRouter>

        <ToastContainer />
        <CSSReset />
        <ReactQueryDevtools />
      </Flex>
    </Providers>,
    document.getElementById('perfect-product-finder'),
  );
};

const init = async () => {
  let messages;

  if (locale === FlowLocale.Custom) {
    await polyfill(translationData?.locale || 'en');

    messages = translationData?.messages?.reduce((all, current) => {
      all[current.key] = current.value;

      return all;
    }, {});
  } else {
    messages = await loadMessages(locale?.toLowerCase() || 'en');
  }

  render(messages);
};

init();
