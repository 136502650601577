export const convertCSStoObject = (cssString) => {
  let cssObject = {};

  //1. Split the string into classes
  const classes = cssString?.split('}');

  //2. Generate an object property for each class
  classes?.forEach((c) => {
    let name = c.split('{')[0].trim();
    let propertiesString = c.split('{')[1];

    //3. Use regex to parse the string of css properties and convert it to a JS object
    let propertiesObject = {};
    var match;
    var regex = /([\w-]*)\s*:\s*([^;]*)/g;
    while ((match = regex.exec(propertiesString)))
      propertiesObject[match[1]] = match[2].trim();

    cssObject[name] = propertiesObject;
  });

  return cssObject;
};
