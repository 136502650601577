import { createContext, ReactNode, useMemo, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import {
  AddSelectionInput,
  EndSessionMutation,
  FlowLocale,
  SessionFragmentFragment,
} from '~/graphql/api/sdk';
import { useFlow, useHeadless, useSdk } from '~/hooks';
import { showToast } from '~/lib';

const messages = defineMessages({
  error: 'An error has occurred.',
});

interface ProviderProps {
  children: ReactNode;
}

export type Session = SessionFragmentFragment & {
  status?: EndSessionMutation['endSession']['status'];
  results?: EndSessionMutation['endSession']['results'];
};

interface ContextProps {
  session: Session | null;
  startSession(flowId: string): Promise<boolean>;
  addSelection(input: Omit<AddSelectionInput, 'sessionId'>): Promise<boolean>;
  endSession(email?: string): Promise<boolean>;
  restartSession(): Promise<boolean>;
  clearSession(): void;
}

export const SessionContext = createContext<ContextProps>({} as ContextProps);

export const SessionProvider = ({ children }: ProviderProps) => {
  const intl = useIntl();
  const { isHeadless, addSessionAttribute } = useHeadless();
  const history = useHistory();
  const { flow } = useFlow();
  const [session, setSession] = useState<Session | null>(null);
  const sdk = useSdk();

  const locale = useMemo(() => {
    if (/^\/(\w{2})\//i.test(window.location.pathname)) {
      return window.location.pathname.split('/')?.[1] || 'en';
    }

    return (
      (flow.locale === FlowLocale.Custom
        ? flow.translation?.locale
        : flow.locale) || 'en'
    );
  }, [window.location.pathname, flow]);

  const startSession = async (flowId: string) => {
    try {
      const result = await sdk.startSession({
        input: {
          flowId,
          referrer: document.referrer,
          screenWidth: window.innerWidth,
        },
      });

      setSession(result.startSession);

      if (isHeadless) {
        addSessionAttribute(result.startSession.id);
      } else if ((window as any).__ppfSetCartSession) {
        (window as any).__ppfSetCartSession(result.startSession.id);
      }

      return true;
    } catch (e: any) {
      showToast(e?.message || intl.formatMessage(messages.error), 'error');
      return false;
    }
  };

  const addSelection = async (input: Omit<AddSelectionInput, 'sessionId'>) => {
    try {
      const result = await sdk.addSelection({
        input: {
          ...input,
          sessionId: session?.id,
        },
      });

      setSession(result.addSelection);

      return true;
    } catch (e: any) {
      if (
        e?.response?.errors?.[0]?.message === 'The session has already ended.'
      ) {
        // Go to results if session has already ended
        history.push('/results');
      } else {
        showToast(e?.message || intl.formatMessage(messages.error), 'error');
      }

      return false;
    }
  };

  const endSession = async (email: string) => {
    if (!session) {
      return false;
    }

    try {
      const result = await sdk.endSession({
        input: { id: session.id, email, locale },
        locale,
      });

      setSession(result.endSession);

      return true;
    } catch (e: any) {
      showToast(e?.message || intl.formatMessage(messages.error), 'error');
      return false;
    }
  };

  const restartSession = async () => {
    if (!session) {
      return false;
    }

    try {
      const result = await sdk.restartSession({ id: session.id });

      setSession(result.restartSession);

      return true;
    } catch (e) {
      return false;
    }
  };

  const clearSession = () => {
    setSession(null);
  };

  return (
    <SessionContext.Provider
      value={{
        session,
        startSession,
        addSelection,
        endSession,
        restartSession,
        clearSession,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};
