import { Box, Flex, Icon, Close, css as cssUi } from '@storyofams/react-ui';
import styled, { css } from 'styled-components';
import { useIsRtl } from '~/hooks';

import { ReactComponent as cross } from '../Icon/library/cross-circle.svg';
import { ReactComponent as info } from '../Icon/library/info-circle.svg';
import { ReactComponent as tick } from '../Icon/library/tick-circle.svg';
import { Subtext } from '../Subtext';

type ToastProps = {
  message: string;
  type: 'info' | 'success' | 'error' | 'alert';
  onClose?: () => void;
};

const content = {
  info: {
    icon: info,
    color: 'primary800',
  },
  success: {
    icon: tick,
    color: 'success600',
  },
  error: {
    icon: cross,
    color: 'error',
  },
  alert: {
    icon: info,
    color: 'error',
  },
};

const Button = styled(Box)<{ isRtl?: boolean }>`
  position: absolute;
  top: 16px;
  appearance: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: inherit;
  text-align: center;
  text-decoration: none;
  border: 0;
  border-radius: 50%;
  padding: 8px;
  transition: background-color 0.18s ease-in-out, color 0.18s ease-in-out;
  overflow: hidden;
  user-select: none;
  font-weight: bold;
  background-color: transparent;

  ${(p) =>
    p.isRtl
      ? css`
          left: 16px;
        `
      : css`
          right: 16px;
        `}

  &:hover {
    background-color: ${(p) => p.theme.colors.black4};
  }

  &:active,
  &:focus {
    background-color: ${(p) => p.theme.colors.black10};
  }
`;

export const Toast = ({ onClose, message, type }: ToastProps) => {
  console.log(message);
  const isRtl = useIsRtl();

  return (
    <Flex
      dir={isRtl ? 'rtl' : undefined}
      p={4}
      width="100%"
      boxShadow="lg"
      borderRadius={['none', 'md']}
      position="relative"
      backgroundColor="white"
      alignItems="center"
    >
      <Icon
        fontSize={3}
        icon={content[type].icon}
        color={content[type].color}
      />
      <Subtext
        fontSize={2}
        color="black90"
        maxWidth="100%"
        overflow="hidden"
        lineHeight={1.5}
        css={cssUi({
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          marginInlineStart: '16px',
        })}
      >
        {message}
      </Subtext>
      <Button as="button" onClick={onClose} isRtl={isRtl}>
        <Icon icon={Close} color="black90" fontSize="12px" />
      </Button>
    </Flex>
  );
};
