import { Box, Flex, Icon, SystemProps, Text } from '@storyofams/react-ui';
import { m, AnimatePresence } from 'framer-motion';
import Imgix from 'react-imgix';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import config from '~/config';
import { File } from '~/graphql/api/sdk';
import { getFileUrl } from '~/lib';
import { ReactComponent as cross } from '../Icon/library/cross.svg';
import { Link } from '../Link';

import { messages } from './messages';

const ImgWrapper = styled.div`
  height: 100%;
  max-height: 48px;
  width: auto;
  max-width: 240px;

  > img {
    max-width: 100%;
    height: 100%;
    width: auto;
    object-fit: contain;
    object-position: center;
  }
`;

export interface TopNavProps extends SystemProps {
  closeText?: string;
  current?: number;
  hasStepIndicator?: boolean;
  hidden?: boolean;
  logo?: File;
  total?: number;
}

export const TopNav = ({
  closeText,
  current,
  hasStepIndicator,
  hidden,
  logo,
  total,
  ...props
}: TopNavProps) => {
  const exit = () => {
    if (window.location.search?.includes('flowId=')) {
      window.close();
    }

    window.location.href = '/';
  };

  return (
    <m.div
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.5, duration: 0.4, ease: [0.42, 0, 0.58, 1] }}
    >
      <Flex
        flexDirection="row"
        alignItems="center"
        width="100%"
        mb={1}
        pt={[2, 4]}
        pb={[3, 4]}
        {...props}
      >
        {!hidden && (
          <>
            <Box flex="1" textAlign="left">
              {!!logo && (
                <Box height="48px">
                  <ImgWrapper>
                    {process.env.NODE_ENV === 'development' ? (
                      <img src={getFileUrl(logo)} alt="" />
                    ) : (
                      <Imgix
                        className="lazyload"
                        src={getFileUrl(logo)}
                        sizes="480px"
                        attributeConfig={{
                          src: 'data-src',
                          srcSet: 'data-srcset',
                          sizes: 'data-sizes',
                        }}
                      />
                    )}
                  </ImgWrapper>
                </Box>
              )}
            </Box>

            {hasStepIndicator && (
              <AnimatePresence>
                {(!!total || !!current) && (
                  <m.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.3, ...config.transition }}
                  >
                    <Text
                      display={['none', 'block']}
                      color="black40"
                      fontSize={2}
                      lineHeight="19px"
                      fontWeight="bold"
                    >
                      <FormattedMessage {...messages.questionIndicator} />{' '}
                      <span dir="ltr">
                        {current}
                        {!!total && ` / ${total}`}
                      </span>
                    </Text>
                  </m.div>
                )}
              </AnimatePresence>
            )}

            <Flex flex="1" flexDirection="column" alignItems="flex-end">
              <Link onClick={exit} tabIndex={-1}>
                <Text display={['none', 'block']}>
                  {closeText || <FormattedMessage {...messages.close} />}
                </Text>
                <Icon icon={cross} css={{ marginInlineStart: '8px' }} />
              </Link>
            </Flex>
          </>
        )}
      </Flex>
    </m.div>
  );
};
