export default {
  graphqlUrl:
    process.env.REACT_APP_GRAPHQL_URL || 'http://localhost:3001/graphql',
  seo: {
    title: 'Perfect product finder',
    description:
      'Answer the questions to get personalized product recommendations.',
  },
  transition: { duration: 0.3, ease: [0.42, 0, 0.58, 1] },
};
