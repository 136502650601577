import { Title, Meta } from 'react-head';

import { FileUnion } from '~/graphql/api/sdk';
import { getFileUrl } from '~/lib';

interface SeoProps {
  description?: string;
  image?: FileUnion;
  title?: string;
}

export const Seo = ({ title, description, image }: SeoProps) => (
  <>
    {!!title && <Title>{title}</Title>}
    {!!description && <Meta name="description" content={description} />}
    {!!image && (
      <Meta
        property="og:image"
        content={`${getFileUrl(image)}?auto=format&w=1200`}
      />
    )}
  </>
);
